<template>
  <div class="vertical-layout">
    <!-- Start Containerbar -->
    <div id="containerbar" class="containerbar authenticate-bg">
      <!-- Start Container -->
      <div class="container">
        <div class="auth-box login-box">
          <!-- Start row -->
          <div class="row no-gutters align-items-center justify-content-center">
            <!-- Start col -->
            <div class="col-md-6 col-lg-5">
              <!-- Start Auth Box -->
              <div class="auth-box-right">
                <div class="card">
                  <div class="card-body">
                    <form>
                      <!-- <div class="form-head">
                        <a href="index.html" class="logo"><img src="@/assets/images/wxLogin.png" class="img-fluid" alt="logo"></a>
                      </div> -->
                      <h4 class="text-primary my-4">登录</h4>
                      <div class="form-group">
                        <input type="text" class="form-control" id="username" placeholder="手机号" required v-model="code">
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control" id="username" placeholder="密码" required v-model="password">
                      </div>
                      <a @click="login" class="btn btn-success btn-lg btn-block font-18">登录</a>
                      <!-- <el-link type="primary" class="mt-2" @click="register">注册诊所</el-link> -->
                    </form>
                    <span style="color:red" v-if="errMsg">{{errMsg}}</span>
                  </div>
                </div>
              </div>
              <!-- End Auth Box -->
            </div>
            <span class="copyright">Copyright © 2021 成都科仕诺信息技术有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备17044143号-2</a></span>
            <!-- End col -->
          </div>
          <!-- End row -->
        </div>
      </div>
      <!-- End Container -->
    </div>
    <!-- End Containerbar -->
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      code: undefined,
      password: undefined
    }
  },

  computed: {
    ...mapState({
      isLogin: state => state.login.isLogin
    }),
    ...mapState('login', ['errMsg'])
  },

  methods: {
    ...mapMutations('login', ['setErrMsg']),
    login () {
      this.setErrMsg('')
      this.$store.dispatch('login/login', {
        code: this.code,
        password: this.password,
        $router: this.$router
      })
    },
    register () {
      this.$router.push({ path: '/register' })
    }
  }
}
</script>

<style scoped lang="less">
.btn-success {
  color: #fff !important;
}
.copyright {
  position: absolute;
  bottom: 1rem;
  right: 4rem;
  color: #888;
  a {
    color: #888;
  }
}
</style>